.hh-slider {
    max-width: 100%;
    position: relative;

    figcaption {
        left: 45px;
        position: absolute;
        top: 10%;
    }

    .pagination {
        list-style: none;

        li {
            display: inline-block;
        }
    }

    .btn-autoplay {
        background-color: #e4685d;
        border: 0;
        display: block;
        cursor: pointer;
        color: #ffffff;
        font-size: 15px;
        height: 30px;
        margin-top: -30px;
        padding: 6px 15px;
        position: relative;
        text-decoration: none;

        &:hover {
            background-color: #eb675e;
        }

        &.disabled {
            display: none;
        }
    }
}

.slider-container.tns-horizontal {
    .slider-slide {
        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.slider-button-prev,
.slider-button-next {
    background-image: url("../Icons/arrows.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200% 100%;
    cursor: pointer;
    display: block;
    height: 60px;
    position: absolute;
    top: calc(50% - (60px / 2));
    width: 42px;
}

.slider-button-prev {
    background-position: 0 0;
    left: 0;
}

.slider-button-next {
    background-position: 100% 0;
    right: 0;
}

.slider-thumbs {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    .slider-slide {
        margin: 10px;
        width: 100px;
    }
}

.tns-inner {
    overflow: hidden;
}

.tns-nav {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    button {
        background-color: $pagination-button-bg;
        border: $pagination-button-border;
        border-radius: 100%;
        display: block;
        height: 20px;
        margin: 5px;
        width: 20px;
        @include prefix((transition: all 400ms));
    }

    .tns-nav-active {
        background-color: $pagination-button-bg-active;
    }
}
